import React from 'react';
import MarkdownRenderer from './MarkdownRenderer';

const CondensedMatterPhysics = () => {
  const markdownContent = `
  # Solid State Physics

  Welcome to the Solid State Physics Notes page.

  Here is an example of an inline equation: $E = \\frac{1}{2}mv^2$.

  And a block equation:

  $$ 
  \\frac{1}{T} = \\frac{R}{L}
  $$ 
  `;

  return (
    <div className="p-4">
      <MarkdownRenderer content={markdownContent} />
    </div>
  );
};

export default CondensedMatterPhysics;
