import React from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
  return (
    <div className="fixed inset-y-0 left-0 bg-gray-800 text-white w-64 p-4">
      <nav className="mt-10">
        <h1 className="text-center text-2xl font-bold mb-4" style={{ marginTop: "20px" }}>Sfetcustudy</h1>
        <Link to="/" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700">
          Home
        </Link>
        
        <Link to="/QuantumMechanics" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700">
          Basic Quantum Mechanics
        </Link>
        <Link to="/CondensedMatterPhysics" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700">
          Solid State Physics
        </Link>
      
      </nav>
    </div>
  );
};

export default NavBar;
