import React from 'react';
import MarkdownRenderer from './MarkdownRenderer';

const QuantumMechanics = () => {
  const markdownContent = `
  # Basic Quantum Mechanics

  Welcome to the Basic Quantum Mechanics Notes page.

  Here is an example of an inline equation: $E = \\hbar \\omega$.

  And a block equation:

  $$ 
  \\psi(x) = A e^{i(kx - \\omega t)}
  $$ 
  `;

  return (
    <div className="p-4">
      <MarkdownRenderer content={markdownContent} />
    </div>
  );
};

export default QuantumMechanics;
